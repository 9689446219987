import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { Hoverable } from 'react-native-web-hover'
import Button from '../button'
import Text from '../TextComponent'
import { Badge } from 'react-native-elements'
import useGoogleTagManager, { GMTNavigationLinkPlacement } from '../../utils/useGoogleTagManager';
import WaitStatus from "../modal/WaitStatus";
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { useQueryNavigator } from '../../context-api/query';
import { authProvider } from '../../authProvider';
import { useIsMobile } from '../../utils/useDeviceWidth'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer'
import { fetchMessageCount } from '../../redux/messages/actions'

const HoverableItem = (props) => {
  const navigator = useQueryNavigator(props.navigation)
  const { itemStyle, drawerLabel, drawerLabelText, navPath, state } = props
  const { routes } = state

  let routeName = "Home"
  if(routes && routes.length > 0) {
    routeName = getFocusedRouteNameFromRoute(routes[0])
  }

  const gtm = useGoogleTagManager();
  const renderActive = (hovered: boolean) => {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text 
          bold={true} 
          style={[props.textStyle ? props.textStyle : {fontFamily: "JekoBold",fontSize: 14},{color:hovered ? '#002C73': "#002C73"}]}
        >{drawerLabel}</Text>
      </View>
    )
  }

  const handlePress = () => {
    gtm.pushNavigation(GMTNavigationLinkPlacement.Header,drawerLabelText)
    navigator.navigate(navPath)
  }

  return (
    <Hoverable >
      {({ hovered }) => (
        <DrawerItem 
          label={ routeName === navPath ? () => renderActive(hovered) : () => drawerLabel} 
          labelStyle={[props.textStyle ? props.textStyle : {fontFamily: "JekoBold",fontSize: 14}
            ,{color:hovered ? '#002C73': "#002C73"}]}
          style={itemStyle}
          onPress={handlePress}
        />
      )}
    </Hoverable>
  )
}

export default function CustomDrawerContent(props) {
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const gtm = useGoogleTagManager();
  const [showWait,setShowWait]= useState(false)
  const [waitStatus,setWaitStatus]= useState(0)
  const messageCount: number = useSelector((state: ApplicationState) => state.messages.messageCount)

  const logOut=()=>{
    authProvider.logout();
  }

  useEffect(() => {
    dispatch(fetchMessageCount())
  }, [])

  return ( isMobile && 
    <DrawerContentScrollView {...props}>
      <DrawerItem
        activeTintColor="red" 
        label={() => (
          <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Button 
              icon={true} 
              size={45}
              iconColor="#002C73" 
              iconName="ios-close" 
              hoveredStyle={{ color: 'rgb(0, 44, 115)'}}
              iconStyle={{ marginRight: -15 }}
              onPress={() => props.navigation.toggleDrawer()} 
            />
          </View>)}
        onPress={() => props.navigation.toggleDrawer()} 
        style={{margin: 0}}
      />
      
      <HoverableItem
        itemStyle={{
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: '24px',
          paddingRight: '24px',
          borderBottomColor: "rgba(0, 44, 115, 0.2)", 
          borderBottomWidth: 1, 
          borderRadius: 0
        }}
        drawerLabel={
          <Text style={{ fontFamily: 'JekoBold', fontSize: '14px' }}>
            Home
          </Text>
        }
        drawerLabelText="Home"
        navPath="Home"
        {...props}
      />
      <HoverableItem
        itemStyle={{
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: '24px',
          paddingRight: '24px',
          borderBottomColor: "rgba(0, 44, 115, 0.2)", 
          borderBottomWidth: 1, 
          borderRadius: 0
        }}
        drawerLabel={
          <Text style={{ fontFamily: 'JekoBold', fontSize: '14px' }}>
            Documents
          </Text>
        }
        drawerLabelText="Documents"
        navPath="Documents"
        {...props}
      />
      <HoverableItem
        itemStyle={{
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: '24px',
          paddingRight: '24px',
          borderBottomColor: "rgba(0, 44, 115, 0.2)", 
          borderBottomWidth: 1, 
          borderRadius: 0
        }}
        drawerLabel={<View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ fontFamily: 'JekoBold', fontSize: '14px' }}>
            Message Center
          </Text>
          {
            !!(messageCount && messageCount > 0) &&
            <Badge containerStyle={{ marginLeft: 3 }} status="error" value={messageCount} />                
          }
        </View>
        }
        drawerLabelText="Message Center"
        navPath="MessageCenter"
        {...props}
      />
      <View style={{
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingTop: '15px',
        paddingBottom: '15px',
        borderBottomColor: "rgba(0, 44, 115, 0.2)", 
        borderBottomWidth: 1, 
        borderRadius: 0, 
      }}>
          <Text style={{color: "#002C73", fontSize: 14, fontFamily: "JekoBold"}}>Account</Text>
      </View>
      <HoverableItem
        itemStyle={{
          marginLeft: 0,
          marginRight: 0,
          marginTop: '10px',
          marginBottom: '-10px',
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '24px',
          paddingRight: '24px',
          borderBottomWidth: 0, 
          borderRadius: 0
        }}
        drawerLabel={
          <Text style={{ fontFamily: 'JekoBold', fontSize: '14px' }}>
            Your Experience
          </Text>
        } 
        drawerLabelText="Your Experience"
        navPath="YourExperience"
        {...props}
      />
      <HoverableItem
        itemStyle={{
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: '24px',
          paddingRight: '24px',
          borderBottomWidth: 0, 
          borderRadius: 0
        }}
        textStyle={{color: "#FFF", fontSize: 14, fontFamily: "JekoBold", padding: 0, margin: 0}}
        drawerLabel={
          <Text style={{ fontFamily: 'JekoBold', fontSize: '14px' }}>
            Account Settings
          </Text>
        }
        drawerLabelText="Account Settings"
        navPath="AccountSettings"
        {...props}
      />
      
      <DrawerItem 
        activeTintColor="red" 
        style={{ marginLeft: '20px'}}
        label={() => <Button onPress={logOut} variant="redesign-logout" label="Sign Out" />}
                onPress={() => {}}
      />
    </DrawerContentScrollView>
  );
}
