import React, { useEffect, useState } from "react";
import { View, ActivityIndicator, StyleSheet, Platform } from "react-native";
import { NavigationContainer, LinkingOptions, DefaultTheme } from "@react-navigation/native";
import { createDrawerNavigator } from '@react-navigation/drawer';
import * as Linking from "expo-linking";
import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import { useFonts } from '@expo-google-fonts/inter';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { ai } from './telemetryService';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { AuthenticationState, authProvider } from './authProvider'

import AppScreens from "./screens/index"
import Images from './constants/Images'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { RootReducer } from './redux/rootReducer'
import CustomDrawerContent from './components/drawermenu'

import TagManager from 'react-gtm-module'
import { QueryProvider } from "./context-api/query";
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import HSIDAuthProvider from "./HSIDAuthProvider";

// initialize google tag manager
TagManager.initialize({
  gtmId: process.env.EXPO_GTM_ID
})

const store = createStore(RootReducer, applyMiddleware(thunk));

const prefix = Linking.makeUrl("/");
const linking: LinkingOptions = {
  prefixes: [prefix],
  config: {
    screens: {
      Login: "login",
      AppScreens: {
        // initialRouteName: "Home",
        screens: {
          Home: "home",
          Documents: "documents",
          MessageCenter: "messageCenter",
          Verification: "verification",
          Diagnostics: "diagnostics",
          YourExperience: "your-experience",
          YourExperienceDetails: "your-experience/details",
          Wizard: "wizard",
          WizardViewer: "wizard-viewer",
          AccountSettings: "account/settings",
          Account: "account",
          Contact: "contact",
          NegotiationIntro: "negotiation/intro",
          NegotiationStory: "negotiation/story",
          NegotiationNumbers: "negotiation/numbers",
          NegotiationOptions: "negotiation/options",
          NegotiationPlan: "negotiation/plan",
          SignOut: 'signout',
          NotFound: '*',
        },
      },
    },
  },
};

const Drawer = createDrawerNavigator();

function App() {
  const [ isLoadingComplete, setLoadingState ] = useState(false)
  const [ expoFontsLoaded, setExpoFonts ] = useState(false)
  let [fontsLoaded] = useFonts({
    'SourceSansPro-Regular': require('./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf'),
    'SourceSansPro-Bold': require('./assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf'),
    'SourceSansPro-Light': require('./assets/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf'),
    'Canela-Bold-Web': require('./assets/fonts/Canela/Canela-Bold-Web.woff'),
    'Canela-BoldItalic-Web': require('./assets/fonts/Canela/Canela-BoldItalic-Web.woff'),
    'Canela-Light-Web': require('./assets/fonts/Canela/Canela-Light-Web.woff'),
    'Canela-Regular-Web': require('./assets/fonts/Canela/Canela-Regular-Web.woff'),
    'Canela-RegularItalic-Web': require('./assets/fonts/Canela/Canela-RegularItalic-Web.woff'),
    'JekoBlack': require('./assets/fonts/Jeko/webFonts/JekoBlack/font.woff'),
    'JekoBlackItalic': require('./assets/fonts/Jeko/webFonts/JekoBlackItalic/font.woff'),
    'JekoBold': require('./assets/fonts/Jeko/webFonts/JekoBold/font.woff'),
    'JekoBoldItalic': require('./assets/fonts/Jeko/webFonts/JekoBoldItalic/font.woff'),
    'JekoExtraBold': require('./assets/fonts/Jeko/webFonts/JekoExtraBold/font.woff'),
    'JekoExtraBoldItalic': require('./assets/fonts/Jeko/webFonts/JekoExtraBoldItalic/font.woff'),
    'JekoExtraLight': require('./assets/fonts/Jeko/webFonts/JekoExtraLight/font.woff'),
    'JekoExtraLightItalic': require('./assets/fonts/Jeko/webFonts/JekoExtraLightItalic/font.woff'),
    'JekoItalicVariable': require('./assets/fonts/Jeko/webFonts/JekoItalicVariable/font.woff'),
    'JekoLight': require('./assets/fonts/Jeko/webFonts/JekoLight/font.woff'),
    'JekoLightItalic': require('./assets/fonts/Jeko/webFonts/JekoLightItalic/font.woff'),
    'JekoMedium': require('./assets/fonts/Jeko/webFonts/JekoMedium/font.woff'),
    'JekoMediumItalic': require('./assets/fonts/Jeko/webFonts/JekoMediumItalic/font.woff'),
    'JekoRegular': require('./assets/fonts/Jeko/webFonts/JekoRegular/font.woff'),
    'JekoRegularItalic': require('./assets/fonts/Jeko/webFonts/JekoRegularItalic/font.woff'),
    'JekoSemiBold': require('./assets/fonts/Jeko/webFonts/JekoSemiBold/font.woff'),
    'JekoSemiBoldItalic': require('./assets/fonts/Jeko/webFonts/JekoSemiBoldItalic/font.woff'),
    'JekoThin': require('./assets/fonts/Jeko/webFonts/JekoThin/font.woff'),
    'JekoThinItalic': require('./assets/fonts/Jeko/webFonts/JekoThinItalic/font.woff'),
    'JekoVariable': require('./assets/fonts/Jeko/webFonts/JekoVariable/font.woff')
  });

  const cacheFonts = (fonts) => {
    return fonts.map(font => Font.loadAsync(font));
  }

  useEffect(() => {
    (async ()=>{
      await cacheFonts([MaterialIcons.font, Ionicons.font]);
      setExpoFonts(true)
    })()
  }, [])

  const loadResourcesAsync = async () => {
    const images = [
      Images.BrandLogo,
      Images.Group3,
      Images.BrandMark,
      Images.CheckBox,
      Images.CheckBoxBlank
    ];

    const cacheImages = images.map(image => {
      return Asset.fromModule(image).downloadAsync();
    }); 
    await Promise.all(cacheImages);
  }

  if(Platform.OS === 'web') {
    if(!fontsLoaded || !expoFontsLoaded || authProvider.authenticationState === AuthenticationState.InProgress) {
      return (
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" />
        </View>
      )
    }
  } else {
    if(!isLoadingComplete || !fontsLoaded || authProvider.authenticationState === AuthenticationState.InProgress) {
      return (
        <AppLoading
          startAsync={loadResourcesAsync}
          onFinish={() => setLoadingState(true)}
        />
      );
    }
  }

  const AppTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: '#FFF',
    },
  };
  
  return (
    <AppInsightsContext.Provider value={ai.reactPlugin}>
      <QueryProvider>
        <Provider store={store}>
          <HSIDAuthProvider provider={authProvider} >
          {
            ({login, logout, authenticationState}) => {
              return (
                <NavigationContainer theme={AppTheme} linking={linking}>
                  <Drawer.Navigator
                    //use drawertype="back" because other types is expanding the screen causing problem with popup overlay
                    drawerType="back"
                    drawerPosition="right"
                    drawerContent={(props) => <CustomDrawerContent {...props} logout={logout} />}
                    drawerStyle={{
                      opacity: 1,
                      backgroundColor: '#ffffff',
                      width: 312
                    }}
                    screenOptions={{ headerShown: false, unmountOnBlur: true }}
                  >
                    <Drawer.Screen name="AppScreens">
                      {(props) => {
                        let routeName = "Home"
                        const { routes } =  props.navigation.dangerouslyGetState()
                        if(routes && routes.length > 0) {
                          routeName = getFocusedRouteNameFromRoute(routes[0])
                        }
                        return <AppScreens {...props} unauthenticated={authenticationState !== AuthenticationState.Authenticated} login={login} logout={logout} />
                      }}
                    </Drawer.Screen>
                  </Drawer.Navigator>
                </NavigationContainer>
              )
            }
          }
          </HSIDAuthProvider>
        </Provider>
      </QueryProvider>
    </AppInsightsContext.Provider>
  );
}

export default App
// export default withAuthentication(App, {
//   provider: authProvider,
//   reduxStore: store
//  });

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
});