import axios from "axios";
import  pkceChallenge from 'pkce-challenge'

export const enum AuthenticationState {
  Unauthenticated = "Unauthenticated",
  InProgress = "InProgress",
  Authenticated = "Authenticated"
}

export interface IAuthProvider{
  authenticationState: AuthenticationState,
  login: ()=> Promise<void>, 
  logout:()=>void, 
  clearSession:()=>void,
  refreshToken:()=>Promise<void>,
  getAccountInfo: ()  => any | null; 
  getAccessToken:()=> string, 
}

interface AuthProxyRequest{
  uri:string,
  body:string,
  contenttype:string
}

interface AuthExchangeToken{
  access_token:string,
  refresh_token:string,
  id_token:string,
  token_type:string,
  expires_in:number
}

const enum AuthSessionStorage {
  idToken = "auth_id_token",
  accessToken = "auth_access_token",
  refreshToken = "auth_refresh_token",
  expires="auth_expires",
  requestedUrl="auth_requested_Url",
  pkceChallenge="auth_pkceChallenge"
}

interface AuthPKCEChallenge{
  code_verifier: string, 
  code_challenge: string
}

export class AuthProvider implements IAuthProvider{

  private readonly baseLocation:string =`${location.protocol}//${location.host}`;
  private expiryTimeout:NodeJS.Timeout;

  public get authenticationState(): AuthenticationState{
    const id_token = window.sessionStorage.getItem(AuthSessionStorage.idToken)
    const access_token = window.sessionStorage.getItem(AuthSessionStorage.accessToken)
    const refresh_token = window.sessionStorage.getItem(AuthSessionStorage.refreshToken)
    const requestedUrl=  window.sessionStorage.getItem(AuthSessionStorage.requestedUrl)

    if (!id_token || !access_token || !refresh_token || this.expired()){
      return AuthenticationState.Unauthenticated
    }
    else if(requestedUrl){
      return AuthenticationState.InProgress
    }
    else{
      return  AuthenticationState.Authenticated;
    }
  }
  
  private expired=()=> {
    const expires = window.sessionStorage.getItem("expires")
    if (!expires) {
      return false
    } else {
      return new Date(expires).getTime() < new Date().getTime() 
    }
  }

  private registerLoginEvent = async (access_token) => {
    let defaultHeaders: any = {
      'Content-Type': 'application/json',
      Accept: '*.*',
      Authorization: access_token?`Bearer ${access_token}`:"",
    };
    const axiosInstance = axios.create({
      headers: defaultHeaders
    });
    try {
      await axiosInstance.post<AuthExchangeToken>(process.env.EXPO_REGISTER_POST_REQUEST);
    }
    catch(e){
      console.error('Call to Register Post Request (Login) API Failed');
    }
  }

  public login=async ()=>{
    if(this.authenticationState === AuthenticationState.Unauthenticated){
      const authCode= this.getAuthCode();
      //To Do: register localhost with https??
      const redirectUri=`${location.protocol}//${location.host}/`
      
      if(authCode){
       try {
          const pkceChallengeCodes:AuthPKCEChallenge= JSON.parse(window.sessionStorage.getItem(AuthSessionStorage.pkceChallenge));
          const authData = new URLSearchParams();
          authData.append('client_id', process.env.EXPO_AUTH_CLIENT_ID)
          authData.append('code_verifier', pkceChallengeCodes.code_verifier)
          authData.append('redirect_uri', redirectUri)
          authData.append('grant_type', 'authorization_code')
          authData.append('code', authCode)
       
          const proxyData:AuthProxyRequest= {
            uri:process.env.EXPO_AUTH_TOKEN,
            body:authData.toString(),
            contenttype:'application/x-www-form-urlencoded'
          }
         
          const response = await axios.post<AuthExchangeToken>(process.env.EXPO_PROXY_POST_REQUEST,proxyData);
         
          if(response.data){
            const { expires_in, access_token } = response.data
            const expires = this.calculateNewExpiry(expires_in)
            this.processTokens({ ...response.data, expires })
            await this.registerLoginEvent(access_token)
            window.location.href=window.sessionStorage.getItem(AuthSessionStorage.requestedUrl)??this.baseLocation
          }
        } catch (error) {
          //TO DO: Take use to some error page???
          console.error('token exchange error');
        }
        finally{
          window.sessionStorage.removeItem(AuthSessionStorage.pkceChallenge)
          window.sessionStorage.removeItem(AuthSessionStorage.requestedUrl);
        }
      }
      else{
        const pkceChallengeCodes:AuthPKCEChallenge = pkceChallenge(128)
        window.sessionStorage.setItem(AuthSessionStorage.requestedUrl, window.location.href)
        window.sessionStorage.setItem(AuthSessionStorage.pkceChallenge, JSON.stringify(pkceChallengeCodes))
        window.location.replace(`${process.env.EXPO_AUTH_AUTHORITY}?client_id=${process.env.EXPO_AUTH_CLIENT_ID}&pfidpadapterid=${process.env.EXPO_AUTH_PF_ADAPTER_ID}&response_type=code&scope=openid+profile+email&redirect_uri=${redirectUri}&code_challenge_method=S256&code_challenge=${pkceChallengeCodes.code_challenge}&portal=Naviguard`)
      }
    
    }
   }

   //redirect to HSID
  public logout=()=>{
    window.sessionStorage.removeItem(AuthSessionStorage.accessToken)
    window.location.href=`${process.env.EXPO_AUTH_LOGOUT}&HTTP_TARGETURL=${location.protocol}//${location.host}/signout`;
  }
  
  //call this on signout page
  public clearSession=()=>{
    window.sessionStorage.removeItem(AuthSessionStorage.idToken);
    window.sessionStorage.removeItem(AuthSessionStorage.accessToken);
    window.sessionStorage.removeItem(AuthSessionStorage.refreshToken);
    window.sessionStorage.removeItem(AuthSessionStorage.expires);
  }

  public refreshToken=async ()=>{
    if(this.authenticationState===AuthenticationState.Authenticated){
      try {
        const refreshData = new URLSearchParams();
        refreshData.append('client_id', process.env.EXPO_AUTH_CLIENT_ID)
        refreshData.append('grant_type', 'refresh_token')
        refreshData.append('refresh_token', window.sessionStorage.getItem(AuthSessionStorage.refreshToken))
    
        const proxyData:AuthProxyRequest= {
          uri:process.env.EXPO_AUTH_TOKEN,
          body:refreshData.toString(),
          contenttype:'application/x-www-form-urlencoded'
        }
      
        const response = await axios.post<AuthExchangeToken>(process.env.EXPO_PROXY_POST_REQUEST,proxyData);
      
        if(response.data){
          const {expires_in } = response.data
          const expires = this.calculateNewExpiry(expires_in)
          this.processTokens({ ...response.data, expires })
        }
      } catch (error) {
        this.logout();
      }
    }
  }

  public getAccountInfo():any{
    return this.decodeIdToken(window.sessionStorage.getItem(AuthSessionStorage.idToken))
  }

  public getAccessToken=()=>{return window.sessionStorage.getItem(AuthSessionStorage.accessToken)}
 

  private getAuthCode():string{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code')
    return code
  }

  private calculateNewExpiry (expires_in:number):number {
    return new Date().getTime() + expires_in * 1000
  }

  private processTokens ({ id_token, access_token, refresh_token, expires }):void {
    id_token && window.sessionStorage.setItem(AuthSessionStorage.idToken, id_token)
    access_token && window.sessionStorage.setItem(AuthSessionStorage.accessToken, access_token)
    refresh_token && window.sessionStorage.setItem(AuthSessionStorage.refreshToken, refresh_token)
    expires && window.sessionStorage.setItem(AuthSessionStorage.expires, new Date(expires).toString())
    this.maintainToken()
  }

  private decodeIdToken(token):string {
    if (token) {
      const base64Url = token.split(".")[1]
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join("")
      )
      return JSON.parse(jsonPayload)
    } else {
      return null
    }
  }

  private maintainToken=()=> {
    const expires = window.sessionStorage.getItem(AuthSessionStorage.expires)
    const delta = new Date(expires).getTime() - new Date().getTime()
    clearTimeout(this.expiryTimeout)
    this.expiryTimeout = setTimeout(async () => {
        await this.refreshToken();
    }, delta)
  }
}

export const authProvider:IAuthProvider = new AuthProvider();

