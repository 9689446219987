import React from "react";
import { View, TouchableOpacity } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import Text from '../TextComponent'
import { Hoverable } from 'react-native-web-hover';
import styled from 'styled-components/native'
import { CaseAdvisor } from '../../redux/cases/types';

export interface AdvisorContentProps {
  advisor: CaseAdvisor;
  onEmailPress:()=>void;
  onPhonePress:()=>void;
}

  
export default function AdvisorContent(props: AdvisorContentProps) {
    const iconStyle={color:"#0074C8"}
    const accountIconSize=69
    const accountBackgroundMarginSize=14;
    const accountBackgroundSize=accountIconSize - 2*accountBackgroundMarginSize;
    return (
        <View style={{flex: 1, alignItems: "center", padding: 15,paddingBottom:40,backgroundColor:"#FFFFFF",borderRadius:14,top:-14}}>
          <View>
            <Text accessibilityRole="heading" ariaLevel="3" bold={true} style={{fontSize: 20, marginBottom: 8}}>Have a question?</Text>
            <Text>Have a question about your case(s), contact a Naviguard Advisor.</Text>
          </View>
            <View style={{marginTop:36,display:'flex',flexDirection:'row'}}>
                <TouchableOpacity onPress={props.onEmailPress} accessible={false} style={{paddingRight:38}}>
                    <MaterialIcons
                        onKeyUp={(ev) => {
                          if(ev.key==='Enter') {
                            props.onEmailPress()
                          }
                        }}
                        style={iconStyle}
                        accessibilityRole="button"
                        accessible={true}
                        accessibilityLabel="Email advisor"
                        name="email" 
                        size={24} />
                </TouchableOpacity>
                <TouchableOpacity onPress={props.onPhonePress} accessible={false} style={{borderLeftWidth:1,borderLeftColor: 'rgba(42, 46, 114, 0.2)',paddingLeft:37}}>
                    <MaterialIcons 
                        onKeyUp={(ev) => {
                          if(ev.key==='Enter') {
                            props.onPhonePress()
                          }
                        }}
                        style={iconStyle}
                        accessibilityRole="button"
                        accessible={true}
                        accessibilityLabel="Call advisor"
                        name="phone" 
                        size={24} 
                        //hoverMode={hovered} 
                        onPress={props.onPhonePress}  />
                </TouchableOpacity>
            </View>
      </View>

    )
}


