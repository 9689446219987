import axios, { AxiosInstance } from 'axios'
import { Platform } from 'react-native'
import { AuthenticationState, authProvider } from '../authProvider'

const axiosInstance = async (useOptumlabsAPI?:boolean,anonymous?:boolean): Promise<AxiosInstance> => {


    let bearerToken=undefined
   
    if(!anonymous && authProvider.authenticationState === AuthenticationState.Authenticated){
        const accessToken = await authProvider.getAccessToken()
        bearerToken=accessToken
    }
   

    // FIXME: move to dotenv
    let suffix = ''
    const env = process.env.ENV || 'nonprod'

    if (env && env !== 'prod') {
        suffix = 'nonprod'
    }

    // use a relative base path for web, and an absolute url for mobile platforms
    const baseUrl =
        Platform.OS === 'ios' || Platform.OS === 'android'
            ? `https://my.naviguard${suffix}.com`
            : ''
    if(useOptumlabsAPI){
        if(suffix)
            suffix=`fn-${suffix}`
        else
            suffix='fn'
    }
    const apiUrl = `${baseUrl}/api/naviguard${suffix ? `-${suffix}` : ''}`

    let defaultHeaders: any = {
        'Content-Type': 'application/json',
        Accept: '*.*',
        Authorization: bearerToken?`Bearer ${bearerToken}`:"",
    }

    var instance = axios.create({
        baseURL: apiUrl,
        headers: defaultHeaders,
    })

    return instance
}

export default axiosInstance
